(function (scope) {
  Vue.component("custom_content_dropdown_list", {
    template: $("#vue-template-custom-content-topbar-list").html(),
    delimiters: ["<%", "%>"],
    data: function () {
      return {
        title: "Customized Content",
      };
    },
    mounted: function () {
      this.$store.dispatch("custom_content/getContentList");
      this.$store.dispatch("custom_content/getTopbarTitle");
      this.$store.dispatch("custom_content/firstVisitCheck");
    },
    watch: {
      topbarTitle: function (title) {
        this.title = title;
      },
      contentList: function (item) {
        return this.$store.getters["custom_content/list"];
      },
    },
    methods: {
      openedState: function (id) {
        return this.$store.getters["custom_content/opened_state"](id);
      },
    },
    computed: {
      contentList: function () {
        return this.$store.getters["custom_content/list"];
      },
      topbarTitle: function () {
        return this.$store.getters["custom_content/topbar_title"];
      },
      firstVisitCheck: function () {
        return this.$store.getters["custom_content/first_visit"];
      },
      first_visit_list: function () {
        return this.$store.getters["custom_content/first_visit_list"];
      },
      iframe_list: function () {
        return this.$store.getters["custom_content/iframe_list"];
      },
    },
  });

  Vue.component("custom_content_dropdown_list_item", {
    template: $("#vue-template-custom-content-topbar-list-item").html(),
    delimiters: ["<%", "%>"],
    props: ["customContentId", "index"],
    methods: {
      gettersParam: function () {
        return this.customContentId;
      },
      showContent: function () {
        var url = this.$store.getters["custom_content/complete_url"](
          this.customContentId
        );
        // Remove trailing '/' or ?email param if it's a mailto link
        if (url.indexOf("mailto:") != -1) {
          if (url.indexOf("/?") != -1) {
            url = url.split("/?")[0];
          } else {
            url = url.substring(0, url.length - 1);
          }
          var open_in_new_tab = this.$store.getters["custom_content/open_tab"](
            this.customContentId
          );
          window.open(url, open_in_new_tab ? "_blank" : "_self");
        } else {
          this.$store.commit(
            "custom_content/iframeSetOpen",
            this.customContentId
          );
        }
      },
    },
    computed: $.extend(
      Vuex.mapGettersParam("custom_content", [
        "id",
        "iframe_url",
        "complete_url",
        "title",
        "open_on_first_login",
        "email_params",
        "icon_path",
      ]),
      {}
    ),
  });

  var iframeList = Vue.component("custom_content_dropdown_iframe_list", {
    template: $("#vue-template-custom-content-topbar-iframe-list").html(),
    delimiters: ["<%", "%>"],
    methods: {
      openedState: function (id) {
        return this.$store.getters["custom_content/opened_state"](id);
      },
    },
    computed: {
      contentList: function () {
        return this.$store.getters["custom_content/list"];
      },
      firstVisitCheck: function () {
        return this.$store.getters["custom_content/first_visit"];
      },
      first_visit_list: function () {
        return this.$store.getters["custom_content/first_visit_list"];
      },
      iframe_list: function () {
        return this.$store.getters["custom_content/iframe_list"];
      },
    },
  });

  var iframeVue = Vue.component("custom_content_dropdown_iframe", {
    template: $("#vue-template-custom-content-topbar-iframe").html(),
    delimiters: ["<%", "%>"],
    props: ["customContentId", "index"],
    mounted: function () {
      if (this.open_tab) {
        this.openPopup();
      }
    },
    methods: {
      gettersParam: function () {
        return this.customContentId;
      },
      closeIframe: function () {
        this.$store.commit(
          "custom_content/iframeSetClose",
          this.customContentId
        );
      },
      modalHidden: function () {
        this.openPopup();
      },
      openPopup: function () {
        var popup_window = window.open(this.complete_url);
        if (
          popup_window == null ||
          popup_window.closed ||
          typeof popup_window.closed == "undefined"
        ) {
        } else {
          this.closeIframe();
        }
      },
    },
    computed: $.extend(
      Vuex.mapGettersParam("custom_content", [
        "id",
        "iframe_url",
        "complete_url",
        "title",
        "open_on_first_login",
        "email_params",
        "icon_path",
        "open_tab",
      ]),
      {}
    ),
  });
})(window);
