(function (scope) {
  Vue.component("pfmodal", {
    template: $("#vue-template-pfmodal").html(),
    delimiters: ["<%", "%>"],
    props: {
      title: String,
      confirmButton: {
        type: String,
        default: "OK",
      },
      cancelButton: {
        type: String,
        default: "Cancel",
      },
      outsideClose: {
        type: Boolean,
        default: false,
      },
      large: {
        type: Boolean,
        default: false,
      },
      small: {
        type: Boolean,
        default: false,
      },
    },
    mounted: function () {
      document.documentElement.classList.add("modal-open");
    },
    beforeDestroy: function () {
      document.documentElement.classList.remove("modal-open");
    },
    methods: {
      confirm: function () {
        this.$emit("confirm");
        this.close();
      },
      cancel: function () {
        this.$emit("cancel");
        this.close();
      },
      close: function () {
        this.$emit("close");
      },
      clickOutside: function () {
        if (this.outsideClose) {
          this.cancel();
        }
      },
    },
  });

  Vue.component("pfmodaltarget", {
    template: $("#vue-template-pfmodaltarget").html(),
    delimiters: ["<%", "%>"],
  });
})(window);
