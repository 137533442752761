/**
 * Created by iar on 5/4/17.
 */

(function (scope) {
  "use strict";

  (scope.onpopstate = function () {
    var match,
      pl = /\+/g, // Regex for replacing addition symbol with a space
      search = /([^&=]+)=?([^&]*)/g,
      decode = function (s) {
        return decodeURIComponent(s.replace(pl, " "));
      },
      query = window.location.search.substring(1);

    scope.urlParams = {};
    while ((match = search.exec(query)))
      scope.urlParams[decode(match[1])] = decode(match[2]);
  })();

  var Topbar = (scope.Topbar = function (loader, notifier) {
    this.loader = $(loader);
    this.notifications_number = 0;
    this.state = {
      counter: 0,
      timer: null,
      factor: 1,
    };
    this.trialState = 0;

    if (LoggedIn) {
      if (scope.hasShareReview) {
        sculpteoNotification("design_view_share").recv(function (msg) {
          if (window.location.pathname.indexOf("app/library") == -1) {
            var btnText =
              "<button class='btn btn-primary fright'>" +
              gettext("Accept") +
              "</button>";
            var message =
              msg.sender_name +
              " " +
              gettext("has invited you to review the design") +
              ' "' +
              msg.design_name +
              '"?';
            message =
              "<p style='margin: auto;display: inline-block'>" +
              message +
              "</p></br>";
            var $notif = topbar.notify(message + btnText, "hint", 0);
            $(".btn", $notif).on("click", function () {
              window.location =
                "/en/app/library/design/" +
                msg.design_uuid +
                "/" +
                msg.design_slug +
                "?review=" +
                msg.channel;
            });
          }
        });
      }

      this.pageWidth = $(window).width();
      this.checkTrialPeriod();
      //this.unsetTrialPeriod();
      $(window).resize(
        function (e) {
          // Do nothing if the height is the same
          if ($(window).width() == this.pageWidth) return;

          // Update new width value
          this.pageWidth = $(window).width();
        }.bind(this)
      );
    }
  });

  /**
   * Set the trial period, two ways, with a number of days or the end date
   * @param {number} days. Number of days before the trial period end.
   * @param {string} endDate. The end date (CET). Currently not available
   */
  Topbar.prototype.setTrialPeriod = function (days, endDate) {
    days = days || null;
    endDate = endDate || null;
    if (days === null || days !== parseInt(days, 10) || days < 1) {
      return $.Deferred().reject().promise();
    }
    return $.ajax(subsiteSettingsUrl, {
      method: "POST",
      data: "trial_expiration_date=" + days,
    })
      .done(function (data) {
        console.log("Trial period set successfully !");
      })
      .error(function (jqXHR) {
        if (jqXHR.responseJSON && jqXHR.responseJSON.error) {
          scope.topbar.notify(jqXHR.responseJSON.error, "error");
        } else {
          console.error(jqXHR.responseJSON);
        }
      });
  };

  /**
   * Unset the trial period
   */
  Topbar.prototype.unsetTrialPeriod = function () {
    return $.ajax(subsiteSettingsUrl, {
      method: "POST",
      data: "trial_expiration_date=0",
    })
      .done(function (data) {
        console.log("Trial period unset successfully !");
      })
      .error(function (jqXHR) {
        if (jqXHR.responseJSON && jqXHR.responseJSON.error) {
          scope.topbar.notify(jqXHR.responseJSON.error, "error");
        } else {
          console.error(jqXHR.responseJSON);
        }
      });
  };

  /**
   * get the expiration datetime of the trial period
   */
  Topbar.prototype.getTrialPeriod = function () {
    return $.ajax(subsiteSettingsUrl, {
      method: "GET",
      data: "trial_expiration_date=1",
    })
      .done(function (data) {
        /*if (data !== null && data.trial_expiration_date !== null) {
				console.log(new Date(data.trial_expiration_date));
			}*/
      })
      .error(function (jqXHR) {
        if (jqXHR.responseJSON && jqXHR.responseJSON.error) {
          scope.topbar.notify(jqXHR.responseJSON.error, "error");
        } else {
          console.error(jqXHR.responseJSON);
        }
      });
  };

  /**
   * Update the remaining time
   */
  var updateRemainingTime = function (current_date, expiration_date) {
    var day_ms = 86400000;
    var hour_ms = 3600000;
    var minute_ms = 60000;
    var result = 0;
    var result_text = "";
    var result_m = 0;
    var result_m_text = "";
    var diff = expiration_date.getTime() - current_date.getTime();
    if (diff > day_ms) {
      result = Math.round(diff / day_ms);
      while (result > 30) {
        result_m += 1;
        result -= 30;
      }
      result_text = result === 1 ? gettext("day") : gettext("days");
      if (result_m > 0)
        result_m_text = result === 1 ? gettext("month") : gettext("months");
    } else if (diff > hour_ms) {
      result = Math.round(diff / hour_ms);
      result_text = result === 1 ? gettext("hour") : gettext("hours");
    } else {
      result = Math.round(diff / minute_ms);
      result_text = result === 1 ? gettext("minute") : gettext("minutes");
    }
    if (result_m > 0) {
      $(".remaining-time").html(
        result_m + " " + result_m_text + " " + result + " " + result_text
      );
    } else {
      $(".remaining-time").html(result + " " + result_text);
    }
    $(".remaining-time").prop("title", expiration_date.toUTCString());
    $("#trialCountdown").removeClass("hidden");
  };

  /**
   * check the expiration of the trial period
   */
  Topbar.prototype.checkTrialPeriod = function () {
    var exp_date = this.getTrialPeriod();
    exp_date.success(
      function (data) {
        if (data !== null && data.trial_expiration_date !== null) {
          var current_date = new Date();
          var expiration_date = new Date(data.trial_expiration_date);
          if (current_date.getTime() >= expiration_date.getTime()) {
            this.trialState = 2;
            $(".top-bar").addClass("trial-ended");
            $("#trialCountdownExpired").removeClass("hidden");
          } else {
            this.trialState = 1;
            $(".top-bar").addClass("trial");
            updateRemainingTime(current_date, expiration_date);
          }
        }
      }.bind(this)
    );
  };

  /**
   * Sets the global loader to an appropriate state.
   */
  Topbar.prototype.loaderState = function (loadType, percentage, stop_anim) {
    var $bar = this.loader;
    $bar.removeClass("indeterminate");
    $bar.addClass("clearTimer");
    var perc = percentage;
    if (percentage === undefined) {
      perc = 20;
    }

    if (stop_anim) {
      $bar.stop(null, true, true);
    }

    switch (loadType) {
      case "indeterminate":
        perc = 20;
        $bar.attr("style", "");
        $(".progress-bar", $bar).css({ width: perc + "%" });
        $bar.addClass("indeterminate");
        break;
      case "determinate":
        $(".progress-bar", $bar).css({ width: perc + "%" });
        $bar.show();
        break;
      case "fake":
        $bar.removeClass("clearTimer");
        this.state.counter = percentage || 0;
        this.state.factor = 1;
        var countUp = function () {
          var state = this.state;
          state.counter = state.counter + state.factor;
          $(".progress-bar", $bar).css("width", state.counter + "%");

          if ($bar.hasClass("clearTimer") || state.counter > 91) {
            clearInterval(state.timer);
          } else if (state.counter >= 10 && state.counter <= 59) {
            var damping = Math.floor(Math.random() * (300 - 25)) + 6;
            state.factor = Math.max((100 - state.counter) / damping, "0.5");
          } else if (state.counter >= 60 && state.counter < 100) {
            var damping = Math.floor(Math.random() * (50 - 25)) + 3;
            state.factor = Math.max((100 - state.counter) / damping, "0.5");
          }
        }.bind(this);
        this.state.timer = setInterval(countUp, 400);
        $bar.show();
        break;
      case "full":
        $(".progress-bar", $bar).css({ width: "100%" });
        var reset_loader = function () {
          this.loaderState();
        }.bind(this);
        setTimeout(reset_loader, 800);
        break;
      default:
        $(".loading").removeClass("loading");
        $bar.hide();
        $(".progress-bar", $bar).css("width", 0);
    }
  };

  Topbar.prototype.hashCode = function (str) {
    var hash = 0,
      i,
      chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  };

  /**
   * Creates a notification at the topbar
   * @param {string} notif_content - A string representing html to put in the notification
   * @param {string} [notif_type='hint'] - The type of notification (hint (default), error or warning, success)
   * @param {number} [timeout=5000] - The time to display the notification, pass 0 to show forever
   * This allow html entitites such as &times; and links to be put in
   *
   * @returns notification as a jQuery object to be removed when done (notifications are persistent,
   * you need to manage their destruction for now
   */
  Topbar.prototype.notify = function (notif_content, notif_type, timeout) {
    var notif_class = "info";
    var typeOf = typeof notif_content;
    typeOf !== "string" ? (notif_content = notif_content.html()) : "";
    var hash = this.hashCode(notif_content);
    var already = $(':contains("' + hash + '")');
    if (already.length > 0 && timeout !== 0) {
      !this.notifications_number[hash]
        ? (this.notifications_number[hash] = 1)
        : "";
      this.notifications_number[hash]++;
      var badge = $(already[3]).find(".badge-number");
      badge.html(this.notifications_number[hash]);
      return $(already[3]);
    } else {
      this.notifications_number = {};
      var notif_icon_class = "pficon-info";
      switch (notif_type) {
        case "error":
          notif_class = "danger";
          notif_icon_class = "pficon-error-circle-o";
          break;
        case "warning":
          notif_class = "warning";
          notif_icon_class = "pficon-warning-triangle-o";
          break;
        case "success":
          notif_class = "success";
          notif_icon_class = "pficon-ok";
          break;
      }

      if (timeout === undefined) {
        var timeout = 8000;
      }

      var cleanup = function () {
        $notifCont.hide(200, function () {
          $(this).remove();
        });
      };

      var $notifCont = $(document.createElement("div"));
      var $notifClose = $(document.createElement("span"));
      var $notifCloseWrp = $(document.createElement("button"));
      var $notifIcon = $(document.createElement("span"));
      var $notifNumber = $(document.createElement("div"));
      var $notifHash = $(document.createElement("div"));
      $notifHash.html(hash);

      $notifCont
        .hide()
        .addClass("toast-pf alert alert-dismissable")
        .addClass("alert-" + notif_class);
      $notifCont.html(notif_content);
      $notifIcon.addClass(notif_icon_class);
      $notifIcon.addClass("pficon");
      $notifNumber.addClass("badge badge-number");
      $notifHash.addClass("hidden");
      $notifCont.prepend($notifNumber);
      $notifCont.prepend($notifHash);
      $notifCont.prepend($notifIcon);
      $notifClose.addClass("pficon pficon-close");
      $notifCloseWrp
        .addClass("close")
        .attr("data-dismiss", "alert")
        .attr("aria-hidden", "true");
      $notifCloseWrp.append($notifClose);
      $notifCont.prepend($notifCloseWrp);
      $notifCloseWrp.on("click", cleanup);

      $("#notifContainer").append($notifCont);
      $notifCont.show(200);

      if (timeout > 0) {
        timeout += 400; // Our desired timeout, plus the animation times
        setTimeout(cleanup, timeout);
      }

      return $notifCont;
    }
  };

  Topbar.prototype.notifyDialog = function (
    msg,
    question,
    msg_yes,
    msg_no,
    notif_type,
    extra_class
  ) {
    var dfd = $.Deferred();
    var btnText = "";
    if (msg) {
      btnText += msg;
    }
    if (question) {
      btnText += "<div style='text-align:center;'>" + question + "</div>";
    }
    btnText += "<div style='text-align:center;'>";
    btnText += "<button class='btn btn-primary'>" + msg_yes + "</button>";
    btnText += "&nbsp;<button class='btn btn-default'>" + msg_no + "</button>";
    btnText += "</div>";
    var $notif = topbar.notify(btnText, notif_type, 0);
    $notif.addClass(extra_class);
    $(".btn-primary", $notif).on(
      "click",
      function () {
        $notif.hide(200, function () {
          $notif.remove();
        });
        dfd.resolve();
      }.bind(this)
    );
    $(".btn-default", $notif).on(
      "click",
      function () {
        $notif.hide(200, function () {
          $notif.remove();
        });
        dfd.reject();
      }.bind(this)
    );
    $(".close", $notif).on(
      "click",
      function () {
        dfd.reject();
      }.bind(this)
    );
    return dfd.promise();
  };

  scope.topbar = new Topbar(".spinner-loader", "#notifContainer");

  // Notification Pop-Up functions
  scope.showNotificationPopUp = function (fullscreen) {
    var $el = $("#notification-pop-up");
    if (fullscreen) {
      $el.css({
        top: "1rem",
        left: "1rem",
        right: "1rem",
        bottom: "1rem",
        width: "auto",
        height: "auto",
      });
    } else {
      var left = $el.outerWidth() / 2;
      $el.css({
        top: "30px",
        left: "50%",
        "margin-left": "-" + left + "px",
      });
    }

    $("#notifContainer").empty();

    $("#notification-pop-up-back").show();
    $el.show();
  };

  $("#pop-up-close-button").on("click", function (e) {
    $("#notification-pop-up-back").hide();
    $("#notification-pop-up").hide();
    if ($(".dont-clear", "#notification-pop-up").length == 0) {
      $("#notification-pop-up .content").html();
    }
  });

  scope.closeNotificationPopUp = function () {
    $("#notification-pop-up-back").hide();
    $("#notification-pop-up").hide();
    $("#notification-pop-up .content").empty();
  };

  scope.hideNotificationPopUp = function () {
    $("#notification-pop-up-back").hide();
    $("#notification-pop-up").hide();
  };

  var DEFAULT_FOOTER = [
    "<div>",
    '<button type="button" class="btn btn-default" data-dismiss="modal">',
    gettext("Cancel"),
    "</button>",
    '<button type="button" class="btn btn-primary" data-action="save">',
    gettext("Save"),
    "</button>",
    "</div>",
  ].join("\n");

  scope.modal = function () {
    if (arguments.length == 1 && arguments[0].length < 10) {
      // Short argument are action on the modal like toggle, show or hide
      return $("[data-view=modalRoot]").modal(arguments[0]);
    } else if (arguments.length == 1 && _.isObject(arguments[0])) {
      var content = arguments[0].content;
      var title = arguments[0].title;
      var footer = arguments[0].footer;
    } else if (1 <= arguments.length && arguments.length <= 3) {
      var content = arguments[0];
      var title = arguments[1] || "";
      var footer = arguments[2] || DEFAULT_FOOTER;
    } else {
      throw "modal take 1-3 arguments";
    }
    $("[data-view=modalTitle]").html(title);
    $("[data-view=modalMain]").html(content);
    $("[data-view=modalFooter]").html(footer);
    $("[data-view=modalRoot]").modal();
    return $("[data-view=modalRoot]");
  };

  scope.toggleExpand = function (e) {
    var tgl = $(e).parent();
    $(tgl).toggleClass("expanded");
  };

  /**
   * Copied from here https://stackoverflow.com/a/133997
   * sends a request to the specified url from a form. this will change the window location.
   * @param {string} path the path to send the post request to
   * @param {object} params the paramiters to add to the url
   * @param {string} [method=post] the method to use on the form
   */

  scope.postToUrl = function (path, params, method) {
    method = method || "post"; // Set method to post by default if not specified.

    // The rest of this code assumes you are not using a library.
    // It can be made less wordy if you use one.
    var form = document.createElement("form");
    form.setAttribute("method", method);
    form.setAttribute("action", path);

    for (var key in params) {
      if (params.hasOwnProperty(key)) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", params[key]);

        form.appendChild(hiddenField);
      }
    }

    document.body.appendChild(form);
    form.submit();
  };

  $(document).ready(function () {
    if (LoggedIn) {
      var topbarModalsVue = new Vue({
        el: "#vue-topbar-modals",
        store: globalStore,
        delimiters: ["<%", "%>"],
      });
      var navbarVue = new Vue({
        el: "#vue-navbar",
        store: globalStore,
        delimiters: ["<%", "%>"],
      });

      scope.iFrameView = Backbone.View.extend({
        template: _.template($("#iframe_container").html()),
        el: "#notification-pop-up .content",
        initialize: function (options) {
          _.extend(this, options);
        },
        render: function () {
          showNotificationPopUp(true);
          this.$el.css({
            height: "100%",
          });
          var context = { iframeurl: this.iframeurl };
          this.$el.html(this.template(context));
          return this;
        },
      });

      // Bind jquery events after vue is initialized, othewise click listeners are removed
      jQuery.feedback({
        ajaxURL: "/en/contact/",
        feedbackButton: ".feedbackLink",
      });
    }
  });
})(window);
