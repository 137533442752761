(function (scope) {
  var addItemInState = function (state, item) {
    item.opened_state = false;
    Vue.set(state.content_items, item.id, item);
    if (state.content_list.indexOf(item.id) === -1) {
      state.content_list.push(item.id);
      state.content_list.sort(function (a, b) {
        return a > b;
      });
    }
  };

  var removeItemFromState = function (state, itemId) {
    var index = state.content_list.indexOf(itemId);
    state.content_list.splice(index, 1);
    Vue["delete"](state.content_items, itemId);
  };

  // only one url allowed for coming up on start-up
  // when a new item is chosen as the one to show on startup,
  // then set all the other values to false

  var custom_content_module = {
    namespaced: true,
    state: {
      topbar_title: "Customized Content",
      content_items: {},
      content_list: [],
      attrGetterField: "content_items",
      first_visit: false,
      id_edit: -1,
      iframe_url_edit: "",
      title_edit: "",
      open_on_first_login_edit: false,
      email_params_edit: false,
      icon_path_edit: "",
      open_tab_edit: false,
      asyncCall: false,
    },
    getters: {
      topbar_title: function (state) {
        return state.topbar_title;
      },
      content_items: function (state) {
        return state.content_items;
      },
      first_visit: function (state) {
        return state.first_visit;
      },
      id_edit: function (state) {
        return state.id_edit;
      },
      list: function (state) {
        return state.content_list;
      },
      iframe_url_edit: function (state) {
        return state.iframe_url_edit;
      },
      title_edit: function (state) {
        return state.title_edit;
      },
      open_on_first_login_edit: function (state) {
        return state.open_on_first_login_edit;
      },
      email_params_edit: function (state) {
        return state.email_params_edit;
      },
      icon_path_edit: function (state) {
        return state.icon_path_edit;
      },
      open_tab_edit: function (state) {
        return state.open_tab_edit;
      },
      asyncCall: function (state) {
        return state.asyncCall;
      },
      id: Vuex.attrGetter("id"),
      iframe_url: Vuex.attrGetter("iframe_url"),
      complete_url: Vuex.attrGetter("complete_url"),
      title: Vuex.attrGetter("title"),
      icon_path: Vuex.attrGetter("icon_path"),
      opened_state: Vuex.attrGetter("opened_state"),
      open_on_first_login: function (state) {
        return function (id) {
          if (state.content_items[id]["event"].hasOwnProperty("events")) {
            return (
              state.content_items[id]["event"]["events"].indexOf("flg") !== -1
            );
          }
          return false;
        };
      },
      open_tab: function (state) {
        return function (id) {
          if (state.content_items[id]["event"].hasOwnProperty("options")) {
            return (
              state.content_items[id]["event"]["options"].indexOf(
                "open_tab"
              ) !== -1
            );
          }
          return false;
        };
      },
      email_params: function (state) {
        return function (id) {
          if (
            state.content_items[id]["url_parameters"].hasOwnProperty("email")
          ) {
            return state.content_items[id]["url_parameters"].hasOwnProperty(
              "email"
            );
          }
          return false;
        };
      },
      iframe_list: function (state) {
        var list = [];
        _.forEach(state.content_items, function (item) {
          if (
            item.event.options &&
            item.event.options.indexOf("open_tab") === -1
          ) {
            list.push(item.id);
          }
        });
        return list;
      },
      first_visit_list: function (state) {
        var list = [];
        _.forEach(state.content_items, function (item) {
          if (item.event.events && item.event.events.indexOf("flg") >= 0) {
            list.push(item.id);
          }
        });
        return list;
      },
    },
    mutations: {
      asyncCallStart: function (state) {
        state.asyncCall = true;
      },
      asyncCallEnd: function (state) {
        state.asyncCall = false;
      },
      addContentItemsToList: function (state, items) {
        _.forEach(items, function (item) {
          item.opened_state = false;
          addItemInState(state, item);
        });
      },
      addTopbarTitle: function (state, title) {
        if (title) {
          state.topbar_title = title;
        }
      },
      setFirstVisitState: function (state, visitState) {
        state.first_visit = visitState;
      },
      resetState: function (state) {
        state.iframe_url_edit = null;
        state.title_edit = null;
        state.open_on_first_login_edit = null;
        state.email_params_edit = null;
        state.icon_path_edit = null;
      },
      editItem: function (state, itemId) {
        state.id_edit = itemId;
        var item = state.content_items[itemId];
        var open_on_first_login, email_params, open_tab;

        if (item && item["event"].hasOwnProperty("events")) {
          open_on_first_login = item["event"]["events"].indexOf("flg") !== -1;
        } else {
          open_on_first_login = false;
        }
        if (item && item["event"].hasOwnProperty("options")) {
          open_tab = item["event"]["options"].indexOf("open_tab") !== -1;
        } else {
          open_tab = false;
        }
        if (item && item["url_parameters"].hasOwnProperty("email")) {
          email_params = item["url_parameters"]["email"];
        } else {
          email_params = false;
        }
        if (item) {
          state.iframe_url_edit = item.iframe_url;
          state.title_edit = item.title;
          state.open_on_first_login_edit = open_on_first_login;
          state.email_params_edit = email_params;
          state.icon_path_edit = item.icon_path;
          state.open_tab_edit = open_tab;
        } else {
          state.iframe_url_edit = null;
          state.title_edit = null;
          state.open_on_first_login_edit = null;
          state.email_params_edit = null;
          state.icon_path_edit = null;
          state.open_tab_edit = null;
        }
      },
      removeItem: function (state, itemId) {
        removeItemFromState(state, itemId);
      },
      iframeSetOpen: function (state, itemId) {
        state.content_items[itemId].opened_state = true;
      },
      iframeSetClose: function (state, itemId) {
        state.content_items[itemId].opened_state = false;
      },
    },
    actions: {
      setTopbarTitle: function (store, title) {
        $.ajax(subsiteSettingsUrl, {
          method: "POST",
          data: "topbar_custom_content_title=" + title,
        }).done(function (data) {
          store.commit("addTopbarTitle", title);
        });
      },
      getTopbarTitle: function (store) {
        $.ajax(subsiteSettingsUrl, {
          method: "GET",
          data: "topbar_custom_content_title",
        }).done(
          function (data) {
            store.commit("addTopbarTitle", data.topbar_custom_content_title);
          }.bind(this)
        );
      },
      getContentList: function (store) {
        return $.get(customContentUrl)
          .done(function (itemList) {
            store.commit("addContentItemsToList", itemList);
          })
          .fail(function (err) {
            console.log("ran into the following error: ", err);
          });
      },
      firstVisitCheck: function (store) {
        return $.get(firstConnectionUrl).done(function (data) {
          var visitState = false;
          // simply setting visitState to true based on one of the items
          if (data.first_connection === true) {
            visitState = true;
          }
          store.commit("setFirstVisitState", visitState);
        });
      },
      saveIconToDb: function (store, img) {
        var formdata = img;

        return $.ajax({
          url: uploadImageAPI,
          data: formdata,
          processData: false,
          cache: false,
          method: "POST",
          contentType: false,
        }).done(function (response) {
          store.commit();
        });
      },
      saveToDb: function (store, data) {
        var allowSave = true;
        var urlValid = true;

        // allows blank values for icon
        for (var key in data) {
          if (key !== "icon_path") {
            if (!data[key]) {
              allowSave = false;
            } else {
              if (data[key].length == 0) {
                allowSave = false;
              }
              if (key === "iframe_url") {
                var url = data[key];
                var pattern =
                  /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
                var pattern_email = /mailto:\S+@\S+\.\S+/;
                if (!pattern.test(url) && !pattern_email.test(url)) {
                  allowSave = false;
                  urlValid = false;
                }
              }
            }
          }
        }
        var dataId = store.getters["id_edit"];
        // move the topbar to components
        if (allowSave === true) {
          store.commit("asyncCallStart");

          var amendedContentUrl = "";
          var type = "POST";
          if (dataId !== -1) {
            type = "PUT";
          }

          if (type == "PUT") {
            amendedContentUrl = customContentUrl + dataId + "/";
          } else if (type == "POST") {
            amendedContentUrl = customContentUrl;
          }
          var newContent = data;
          var event = data.event;
          var url_params = data.url_parameters;

          newContent["event"] = JSON.stringify(event);
          newContent["url_parameters"] = JSON.stringify(url_params);

          return $.ajax({
            url: amendedContentUrl,
            type: type,
            data: JSON.stringify(newContent),
            contentType: "application/json",
          })
            .done(function (response) {
              store.commit("addContentItemsToList", [response]);
              store.commit("resetState");
              $("#edit-custom-content-modal").modal("hide");
            })
            .fail(function (err) {
              if (err.status == 400 && err.responseJSON) {
                topbar.notify(err.responseJSON.message, "error");
              } else {
                topbar.notify(gettext("An error occurred"), "error");
              }
            })
            .always(function () {
              store.commit("asyncCallEnd");
            });
        } else {
          if (urlValid) {
            topbar.notify(
              gettext("Please fill out all fields before saving"),
              "error"
            );
          } else {
            topbar.notify(
              gettext("Please enter a valid URL or a mailto:you@example.org"),
              "error"
            );
          }
        }
      },
      removeItem: function (store, itemId) {
        return $.ajax({
          url: customContentUrl + itemId,
          method: "DELETE",
        }).done(function () {
          store.commit("removeItem", itemId);
        });
      },
    },
  };
  scope.globalStore.registerModule("custom_content", custom_content_module);
})(window);
