(function (scope) {
  Vue.component("tech_support_logs", {
    template: $("#vue-template-tech-support-logs").html(),
    delimiters: ["<%", "%>"],
    data: function () {
      return {
        message: "",
        modalLogVisible: false,
      };
    },
    mounted: function () {
      this.message = window.localStorage.getItem("tech_log_message");
    },
    methods: {
      keepMessage: function (e) {
        this.message = e.target.value;
        window.localStorage.setItem("tech_log_message", this.message);
      },
      submitTechLog: function () {
        $.post(techSupportLogsUrl, {
          message: this.message,
        }).done(
          function () {
            this.modalLogVisible = false;
            topbar.notify(gettext("Your request has been sent"), "success");
          }.bind(this)
        );
        return false;
      },
    },
  });

  Vue.component("tech_support_modal", {
    template: $("#vue-template-tech-support-modal").html(),
    delimiters: ["<%", "%>"],
    data: function () {
      return {
        hours: 0,
        timeRemaining: 0,
        noneAvailable: true,
        modalVisible: false,
      };
    },
    mounted: function () {
      globalStore.dispatch("tech_support/fetch");
    },
    methods: {
      openModal: function () {
        this.modalVisible = true;
        this.$store.dispatch("tech_support/fetch");
      },
      submitTechSupport: function () {
        var hours = this.hours;
        $.post(techSupportUrl, {
          hours: hours,
        }).done(
          function (data) {
            this.modalVisible = false;
            topbar.notify(gettext("Your request has been sent"), "success");
          }.bind(this)
        );
        return false;
      },
    },
    watch: {
      globalHours: function (time) {
        this.timeRemaining = time;
      },
    },
    computed: {
      globalHours: function () {
        return globalStore.getters["tech_support/get"];
      },
    },
  });
})(window);
