var FeatureSet = (function (FeatureSet) {
  var hasDatePicker = (function () {
    var input = document.createElement("input");
    input.setAttribute("type", "date");

    var notADateValue = "not-a-date";
    input.setAttribute("value", notADateValue);

    return input.value !== notADateValue;
  })();

  var isDragDropSupported = (function () {
    var div = document.createElement("div");
    return (
      ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
      "FormData" in window &&
      "FileReader" in window
    );
  })();

  var isWebGLSupported = (function () {
    var canvas = document.createElement("canvas");
    var gl =
      canvas.getContext("webgl") || canvas.getContext("experimental-webgl");

    if (gl && gl instanceof WebGLRenderingContext) {
      console.log("WebGL supported");
      gl = true;
    } else {
      console.log("WebGL NOT supported");
      var notif = gettext("WebGL is required and was not detected.");
      topbar.notify(gettext(notif), "error");
      gl = false;
    }

    return gl;
  })();

  return {
    hasDatePicker: hasDatePicker,
    isDragDropSupported: isDragDropSupported,
    isWebGLSupported: isWebGLSupported,
  };
})(FeatureSet || {});
