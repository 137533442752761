(function (scope) {
  var tech_support_module = {
    namespaced: true,
    state: {
      hours: 0,
    },
    getters: {
      get: function (state) {
        return state.hours;
      },
    },
    mutations: {
      setTime: function (state, time) {
        state.hours = time;
      },
    },
    actions: {
      fetch: function (store) {
        $.get(techSupportUrl).done(function (data) {
          var timeRemaining = 0;
          if (data.end_time) {
            var date = new Date(data.end_time);
            var now = new Date();
            timeRemaining = Math.abs(date.getTime() - now.getTime());
            timeRemaining = Math.ceil(timeRemaining / (1000 * 3600));
          }
          store.commit("setTime", timeRemaining);
        });
      },
    },
  };

  scope.globalStore.registerModule("tech_support", tech_support_module);
})(window);
