/**
 * Created by iar on 5/12/17.
 */

if (!$._Deferred) {
  $._Deferred = $.Deferred;
  $.Deferred = function (param) {
    var deferredToExtend = $._Deferred(param);

    deferredToExtend.promise()["catch"] = function (fn) {
      var fn2 = function () {
        try {
          fn.apply(this, arguments);
        } catch (error) {
          return arguments[0];
        }
      };
      return deferredToExtend.promise().then(null, fn2);
    };

    return deferredToExtend;
  };
}

$.ajaxPrefilter(function (options, originalOptions, jqXHR) {
  // Set up default options to use for retrying requests
  var defaultOptions = {
    maxRetries: 6, // Make 5 attempts at the API call before failing
    delay: 500, // ms to delay for between each call multiplied by remaining attempts (e.g. 1000, 2000, 3000, 4000, 5000)
  };

  // Don't infinitely recurse
  originalOptions._retry = isNaN(originalOptions._retry)
    ? defaultOptions.maxRetries
    : originalOptions._retry - 1;

  // Store _maxRetries, defaulting to the value of _retry
  originalOptions._maxRetries =
    originalOptions._maxRetries || originalOptions._retry;

  // save the original success callback for later
  if (originalOptions.success) {
    originalOptions._success = originalOptions.success;
  }

  // save the original error callback for later
  if (originalOptions.error) {
    originalOptions._error = originalOptions.error;
  }

  // overwrite *current request* success and error callback
  options.success = jQuery.noop();
  options.error = jQuery.noop();

  // setup our own deferred object to also support promises that are only invoked
  // once all of the retry attempts have been exhausted
  var dfd = jQuery.Deferred();
  if (originalOptions._success) dfd.done(originalOptions._success);
  jqXHR.done(dfd.resolve);

  // if the request fails, do something else yet still resolve
  jqXHR.fail(function () {
    var args = Array.prototype.slice.call(arguments);
    var retriesRemaining = originalOptions._retry;
    var maxRetries = originalOptions._maxRetries;
    var httpStatus = jqXHR.status;

    if (
      (httpStatus === 0 || httpStatus == 409 || httpStatus == 502) &&
      retriesRemaining > 0
    ) {
      var delay = (maxRetries - retriesRemaining) * defaultOptions.delay;
      console.warn(
        "Request to " +
          (jqXHR.url || originalOptions.url) +
          " failed. Retrying " +
          retriesRemaining +
          " more times. Next retry in " +
          delay +
          " ms"
      );
      // retry with our modified deferred object after a delay.
      setTimeout(function () {
        jQuery.ajax(originalOptions).then(dfd.resolve, dfd.reject);
      }, delay);
    } else {
      if (retriesRemaining == 0) {
        topbar.loaderState();
        topbar.notify(
          "Connection error, please check your connection and try again",
          "error",
          0
        );
      }
      // add our _error callback to our promise object
      if (originalOptions._error) {
        dfd.fail(originalOptions._error);
      }
      dfd.rejectWith(jqXHR, args);
    }
  });

  // NOW override the jqXHR's promise functions with our deferred
  return dfd.promise(jqXHR);
});
